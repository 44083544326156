import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { Constants } from "../../../../constants/constants";
import { AwsAccountsService } from "../aws-accounts.service";
import { AwsAccount } from "../../../../models/shared";
import { TitleService } from "../../../../services/title.service";
import { take } from "rxjs/operators";

@Component({
    selector: "app-aws-account-form",
    templateUrl: "./aws-account-form.component.html"
})
export class AwsAccountFormComponent implements OnInit, OnDestroy {
    awsAccount: AwsAccount;
    awsAccountNames: string[];
    accountId: number;
    action: string;

    submitted = false;
    minLength = 2;
    saving = false;
    isEdit: boolean;
    loading = true;
    constants = Constants;

    private awsAccountsSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ts: AwsAccountsService,
        private titleService: TitleService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.accountId = parseInt(params.get("id"), 10);
            this.action = params.get("action");
            if (this.accountId != null) {
                this.ts.refreshAwsAccounts();

                this.ts.awsAccounts.pipe(take(1)).subscribe(awsAccounts => {
                    this.awsAccount = Object.assign(
                        {},
                        awsAccounts.find(t => t.id === this.accountId)
                    );
                    this.prepForm();
                    this.loading = false;
                });
            } else {
                this.prepForm();
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.action) {
            if (this.action === "edit") this.isEdit = true;
        }

        if (!this.awsAccount && !this.isEdit) {
            this.awsAccount = new AwsAccount();
            this.awsAccount.name = "";
        }

        // Set Title
        this.titleService.setTitle("AWS_ACCOUNT", this.action, this.awsAccount);
    }

    ngOnInit() {
        this.ts.refreshAwsAccounts();

        this.awsAccountsSubscription = this.ts.awsAccounts.subscribe((awsAccounts: AwsAccount[]) => {
            if (this.isEdit) this.awsAccountNames = awsAccounts.filter(t => t.id !== this.accountId).map(t => t.name);
            else this.awsAccountNames = awsAccounts.map(t => t.name);
        });
    }

    ngOnDestroy() {
        if (this.awsAccountsSubscription) this.awsAccountsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.awsAccount.name,
            iam_role_arn: this.awsAccount.iam_role_arn
        };

        if (this.isEdit) {
            const result = await this.ts.updateAwsAccount(this.awsAccount, model);
            if (result !== false) {
                this.saving = false;
                this.router.navigate([Constants.urls.configuration.aws]);
            } else this.saving = false;
        } else {
            const result = await this.ts.addAwsAccount(model);
            if (result !== false) {
                this.saving = false;
                this.router.navigate([Constants.urls.configuration.aws]);
            } else this.saving = false;
        }
    }

    cancel() {
        this.router.navigate([Constants.urls.configuration.aws]);
    }
}
